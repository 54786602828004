.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color: #111;
  }

  .tableCell {
    color: gray;
  }

  .navbar {
    color: #999;
    border-color: #333;
  }

  .search {
    border-color: gray;
  }

  .sidebar {
    background-color: #111;
    border-color: #333;
  }

  .logo {
    color: #999;
  }

  hr {
    border-color: #333;
  }

  li:hover {
    background-color: #333;
  }

  .icon {
    color: #999;
  }

  .datagrid {
    color: gray;
    border: none;
  }

  .viewButton,
  .deleteButton,
  .cellWithStatus {
    color: gray;
    border: none;
  }

  .login {
    background-color: #333;

    p,
    label {
      color: #333;
    }
  }
}

input {
  background-color: transparent;
}

.list {
  display: flex;
  width: 100%;

  .listContainer {
    flex: 6;
  }
}